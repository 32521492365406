<template>
  <div class="outerDiv" :style="configStyle">
    <div class="logoDiv">
        <img alt="logo" :src="logoPath()"  class="logo">
    </div>
    
    <div class="currentScreenDiv">
        <!-- Password -->
        <div v-if='isCurrentScreen("login")' class="verticalFlex">
            <p class="screenTitle">{{loginPrompt}}</p>
            <input ref="userText" class="LoginText" v-model="userText"/>
            <input ref="passText" class="LoginText" v-model="passText"  @keyup.enter='loginEmployee' />
            <button class="Button" v-on:click='loginEmployee'>{{loginButtonText}}</button>
            <p class="scanMessage">{{messageOnScan}}</p>
        </div>

         <!-- select location -->
        <div v-if='isCurrentScreen("selectLocation")' class="verticalFlex">
             <p class="screenTitle">{{selectLocationPrompt}}</p>
            <div class="horixFlex">
                <button :class='buttonclass(loc)' v-for="loc in locations" :key="loc.id" 
                    v-on:click='selectCheckInLocation(loc.id, loc.name)' >{{loc.name}}</button>
            </div>
        </div>

        <!-- ScanBadge -->
        <div v-if='isCurrentScreen("scanBadge")' class="verticalFlex">
            <p class="screenTitle">{{scanPrompt}} for {{selectedLocation.name}}</p>
            <button v-if="showSwitch"  v-on:click='showSelectLocation' class='linkButton_switch'>{{switchButtonText}}</button>
            <!-- <h2 class="screenTitle">{{monitorScanResultMessage}}</h2> -->

            
            <input ref="badgeText" id="badgeText" v-model="badgeValue" class='scanText' @keyup.enter='scanUserBadge' @keyup='timeSinceResult=0' />
            <button v-on:click='scanUserBadge' class='Button ' v-show="!pleaseWait">{{scanButtonText}}</button>
            <div class="wait" v-show="pleaseWait">
                <label for="">Please Wait...</label>
            </div>
            <div class="personPhoto" v-if="scannedPerson.badge!=undefined">
                <img :src="getImage(scannedPerson.badge)"  width="150" :class="imageclass" >
            </div>
            <p class="scanMessage" v-if="messageString!=null">{{messageString}}</p>
            <p class="scheduleMessage" v-if="scheduleMessage!=null">{{scheduleMessage}}</p>
            <p class="thankMessage">{{thanksMessageForFront}}</p>
            <p class="scanMessage">{{messageOnScan}}</p>
            <button v-on:click='clockIn' class='Button ' v-show="showClockInButton">{{clockInButtonText}}</button>
            <!-- <button class="Button" v-if="isTardy" v-print="'#printMe'" >Print Tardy Slip</button> -->
            <button ref="tardybutton" v-print='printObj' class="Button" v-if="isTardy">Print Tardy Slip</button>
            <!-- <button  class="Button" v-if="isTardy" @click="printtardy">Print Tardy Slip</button> -->
        </div>  

        <div v-if="isTardy" class="horizFlexSpace">
            <div id="printMe" class="tardyslip" ref="fragment">
                <p>Tardy for {{selectedLocation.name}}</p>
                <div class="personPhoto" v-if="scannedPerson.badge!=undefined">
                    <img :src="getImage(scannedPerson.badge)"  width="150" class="image" >
                </div>
                <p class="tardydetail">{{scannedPerson.firstName}} {{scannedPerson.lastName}}</p>
                <p class="tardydetail"> {{nowDateTime}}</p>
            </div>
            <PrintPage ref="frame"/>
            
        </div>
        
    </div>

    <!-- link buttons -->
    <div class="linkButtonArea">
        <p class="Message">{{monitorTitle}}</p>
        <!-- <button v-if="showSwitch"  v-on:click='showSelectLocation' class='linkButton'>{{switchButtonText}}</button> -->
        <button v-if="showLogout" v-on:click='logoutEmployee' class='linkButton'>{{logoutButtonText}}</button>     
    </div>

    <footer>
        <div>
          
          
        </div>
    </footer>

  </div>  
</template>

<script>
import PrintPage from '../components/printpage.vue';
import {mapFields} from 'vuex-map-fields'
import datamethods from '../services/datamethods.js'
import style from '../style/style.js'
import format from '../style/format.js'
// import print from 'vue-print-nb'
export default {
    mixins:[datamethods, format,style],
    components:{
        PrintPage
    },
    data(){
        return{
            allowStoreLogin:true,
            token:null,

            listenersConnected:true,

            scheduleMessage:null,
            messageString:null,

            imageBasePath:'',

            switchButtonText:'Switch Location',
            logoutButtonText:'Logout',
            cancelButtonText:'Cancel',

            

            showSwitch:false,
            showCancel:false,
            showLogout:false,

            userText:'',
            passText:'',
            isLoggedIn:false,
            loggedInPerson:{},

            currentScreen:'login',
            monitorTitle:'',
            messageOnScan:'',
            

            locations:[],
            selectedLocation:{},

            
            
            badgeValue:'',
            scannedPerson:{
                id:0,
                name:null,
            },
            isTardy:false,

            loginPrompt:'Please enter your email and password',
            loginButtonText:'Login',
            selectLocationPrompt:'Select Location to Clock Into',
            scanPrompt:'Scan Badge',
            scanButtonText:'Scan Me',
            checkedInMessage:' clocked in to ',
            employeeCheckingInForPrompt:' clocking students into ',
            printObj:{
                id:"printMe",
                preview:false,
                popTitle:'StudentTrack'
            },
            showClockInButton:false,
            clockInButtonText:'Clock In',
            selectedLunchPeriod:'',
            nowDateTime:'',
            timeSinceResult:0,
        }
    },
    computed:{
        ...mapFields([
            'pleaseWait',
            'message'
        ]),
        
        imageclass(){
            if(this.selectedLunchPeriod==''){return "image"}
            else if(this.scannedPerson.lunchPeriod==this.selectedLunchPeriod){
                return "image-yes"
            }else{return "image-no"}
        }
    },

    methods:{
        printtardy(){
            this.$refs.frame.print(this.$refs.fragment)
        },
        getnowDateTime(){
            this.nowDateTime= this.$options.filters.formatDateTime_Today()
        },
        isCurrentScreen(screenName){
            return this.currentScreen==screenName;
        },
        setCurrentScreen(screenName){
            this.currentScreen=screenName;
        },
        logoPath(){
            return './../' + window.SCHOOL_LOGO
        },
        buttonclass(club){
            return 'Button '
           // else{return 'ButtonInactive bold'}
        },
        print () {
            this.isTardy=false //hide
            // Pass the element id here
            this.$htmlToPaper('printMe');
            
        },
        prelogin(){
            
            if(this.allowStoreLogin){
                console.log('pre')
                this.token = localStorage.token
                //console.log('t' + this.token)
                if(this.token==null){
                    
                    return 
                }
                if(this.token=='' || this.token=='null'){
                    
                    return
                }

                //now we call our login, with the token! (no username or pwd)
                this.post_login(this.token)
            }
            else{
                
            }
        },
        async loginEmployee(){
            this.thanksMessageForFront=''
          
            let req = {
                username:this.userText,
                password:this.passText
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'securitylogin')
            //**OLD LOGIN** */ var response = await this.callAPI(req,'login')
            
            //handle response
            if(response.Successful){
                    //do the rest in post...deal with token
                    this.post_login(response.securityInfo.token)
                    /* this.loggedInPerson = response.person
                    this.isLoggedIn=true
                
                    //if IS monitor - go to clubs
                    this.getLocations()
                    //else - msg... */
               
            }else{
                this.messageOnScan=response.Message
            }
            this.pleaseWait=false;
            
        },
        async post_login(token){
            //console.log('t' + token)
            this.token=token
            //add to api
            this.AddTokenToAPI(token)

            //store?
            if(this.allowStoreLogin){
                localStorage.token=token;
            }

            //no username/pwd
            this.responseMessage='';
            //validate
            var response = await this.callAPI(null,'adminlogin')
            //handle response
            if(response.Successful){
                console.log(response)
                this.loggedInPerson= response.person
                this.loggedIn=true
                
                
                //if IS monitor - go to clubs
                this.getLocations()
            }else{
               this.loggedIn=false
                this.responseMessage = response.Message

            }
        },
        logoutEmployee(){
            this.isLoggedIn=false
            localStorage.token="";
            this.passText=''
            this.monitorTitle=''
            this.setCurrentScreen('login')
            this.isTardy=false;
        },
        async getLocations(){
            let req = {
                memberId:-1
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'clubs')
            
            //handle response
            if(response.Successful){
                var tmp = response.ClubList
                tmp.splice(0,1)
                this.locations = tmp
                this.showSelectLocation()
            }else{
                this.messageOnScan=response.Message;
                
            }
            this.pleaseWait=false;
        
        },
        showSelectLocation(){
            //this.getClubs();
            this.setCurrentScreen('selectLocation');
            this.showLogout=true;
            this.showSwitch=false;
            this.isTardy=false;
        },
        selectCheckInLocation(locationId, locationName){
            this.selectedLocation={id:locationId, name:locationName}
            this.monitorTitle=this.loggedInPerson.name  + this.employeeCheckingInForPrompt + this.selectedLocation.name
            this.showScanBadge()
            
            //determine lunch period if ther eis one
            if(locationName.includes('Lunch')){
                this.selectedLunchPeriod=locationName.replace("Lunch ", "")
            }else{
                this.selectedLunchPeriod=''
            }
        },
        clearResults(){
            this.scannedPerson={
                id:0,
                name:null,
            },
            this.messageOnScan='';
            this.thanksMessageForFront='';
            this.scheduleMessage=''
            this.messageString=''
            this.showClockInButton=false
        },
        showScanBadge(){
            console.log('showscan')
            this.setCurrentScreen('scanBadge');
            this.messageOnScan=''
            this.showLogout=true;
            this.showSwitch=true;
            this.isTardy=false;
            this.clearResults()
            this.clearAndSetFocus()
        },
        async scanUserBadge(){
            this.clearResults()
            
            //disallow empty scan
            if(this.badgeValue==null || this.badgeValue==''){
                return;
            }
            
            let req = {
                lookupText:this.badgeValue,
                clubsOnly:true,
                club:this.selectedLocation.id,
                ipAddress:this.myip
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'lookupbadge')
            
            //handle response
            if(response.Successful){
                if(response.Person.isActive){
                    console.log('scanning...' + this.badgeValue)
                    this.getnowDateTime()
                    this.scannedPerson = response.Person
                    this.scheduleMessage=this.scannedPerson.scheduleString
                    this.messageString= this.scannedPerson.messageString;
                    /* if(this.scannedPerson.messageString==null){
                        this.scheduleMessage=null;
                        this.messageString=null;
                    }
                    else if(this.scannedPerson.messageString.includes('Period:')){
                        this.scheduleMessage=this.scannedPerson.messageString;
                        this.messageString = null
                    }else{
                        this.messageString= this.scannedPerson.messageString;
                        this.scheduleMessage=null
                    }
 */
                    var msg = response.inactiveMessage
                    if(response.AllowAutoClockIn){
                        this.timeSinceResult=0
                        this.checkIn(this.scannedPerson.id, this.selectedLocation.id,this.selectedLocation.name,msg);
                    }else{
                        this.showClockInButton=true
                        this.timeSinceResult=0
                    }
                    
                
                }else{
                    this.timeSinceResult=0
                    this.messageOnScan=response.inactiveMessage
                    this.clearAndSetFocus()
                }
            }else{
                console.log(response.Message)
                this.messageOnScan=response.Message//"Your badge failed to scan. Please try again"
                this.clearAndSetFocus()
            }
            this.pleaseWait=false;
        },
        async checkIn(personID, clubID,name, sMessage){
            this.showClockInButton=false
            let req = {
                personID:personID, 
                clubID:clubID, 
                guestCount:this.guestCount,
                clubName:name,
                ipAddress:this.myip,
                note:sMessage
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'checkin')
            
            //handle response
            if(response.Successful){
                this.thanksMessageForFront=this.scannedPerson.firstName + ' ' + this.scannedPerson.lastName + this.checkedInMessage + this.selectedLocation.name
                this.messageOnScan=sMessage
                
                //handle tardy case
                if(sMessage==null){this.isTardy=false}
                else{this.isTardy = sMessage.includes('Tardy')}

                this.$nextTick(() => {
                    if(this.isTardy){
                        this.$refs.tardybutton.click();
                    }
                })

                this.clearAndSetFocus()
            }else{
                this.messageOnScan=response.Message;
                this.clearAndSetFocus()
            }
            this.pleaseWait=false;
       
      },
      clockIn(){
        this.checkIn(this.scannedPerson.id, this.selectedLocation.id,this.selectedLocation.name,null);
      },
      clearAndSetFocus(){
       
       this.badgeValue=""
        
        
        this.$nextTick(() => {
            this.$refs.badgeText.focus()
        })
      },
      getImageBasePath(){
        this.imageBasePath = window.VUE_CONFIG_API + 'personimage/'
            console.log("img path " + this.imageBasePath)
      },
      getImage(imagePath){
       if(this.scannedPerson==null){return null}
        return this.imageBasePath + imagePath;
      },
      autoClear(){
        if(this.currentScreen=='scanBadge'){
            console.log('clear and focus ' + this.timeSinceResult)
            if(this.timeSinceResult>=5){
                this.timeSinceResult=0
                this.clearAndSetFocus()
            }else{
                this.timeSinceResult=this.timeSinceResult+1
            }
            
         }
        
      }
    },
    created(){
        const timer = setInterval(()=>{
          this.autoClear();
        },1000);
    
    
  },
    mounted(){
        this.setColors();
        this.getImageBasePath();

        this.prelogin()
    },
}
</script>

<style lang="scss">
@import "@/assets/stylebase.scss";
body{
    font-size:x-large;
}
.image-yes{
    border:15px green solid;
}
.image-no{
    border:15px red solid;
}
.outerDiv{
  height:90vh;
  display:grid;
  grid-template-columns:1fr 4fr 1fr;
  grid-template-rows:100px auto 80px 80px;
  grid-template-areas: 
    "header header header"
    "left center right"
    "button button button"
    "footer footer footer";
}
.currentScreenDiv{
  background-color: var(--main-background-color);
  border-radius: 5px;
  padding:5px;
  grid-area:center;

  /* display:grid;
  grid-template-rows:auto;
  grid-template-columns:150px auto 150px;
  text-align: center; */
}
.logo{
  height:90px;
}
.logoDiv{
    grid-area:header;
}
/* link buttons */
.linkButtonArea{
  grid-area:button;
}
.linkButton{
  background-color: transparent;
  color: rgb(0,158,192);
  font-size:large;
  margin:20px;
  border:none;
}
.linkButton_switch{
  background-color: transparent;
  color: rgb(0,158,192);
  font-size:large;
  margin:5px;
  border:none;
  text-decoration: underline;
}
.verticalFlex{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content:space-around;
  padding:20px;
}
.screenTitle{
  margin:5px 10px 0px 10px;
}
.Button{
  background-color:var(--primary-color);
  color:var(--light-text-color);
   padding:10px;
   border-radius:5px;
   border:none;
   margin:10px 5px 10px 5px;
   min-width: 140px;
  font-size:inherit;
}
.scanText{
  border-radius: 5px;
  margin:5px;
  border:none;
  width:200px;
  font-size:inherit;
}
.scanText:focus{
  outline-color:var(--highlight-color);
}
.LoginText{
  border-radius: 5px;
  margin:5px;
  border:none;
  width:200px;
  font-size:medium;
  padding:5px;
}
.LoginText:focus{
  outline-color:var(--highlight-color);
}
.scanMessage{
  margin:10px;
  color:red;
}
.scheduleMessage{
    margin:10px;
    font-size:small;
    white-space: pre-wrap;
}
.thankMessage{
  margin:10px;
  color:green;

}
.Message{
  margin:10px;
  font-size:medium;
}

.ButtonInactive{
  background-color:var(--inactive-color);
  color:black;
   padding:15px;
   border-radius:20px;
   border:none;
   font-size:x-large;
   margin:10px 5px 10px 5px;
   min-width: 140px;
  
}
.tardyslip{
    border:1px solid darkgray;
    width:260px;
    height:330px;
    background-color:white;
    padding:5px;
    text-align: center;
     display: block; page-break-before: always; 
}
.tardydetail{
    font-size:medium;
}
</style>